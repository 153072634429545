import { MarketDatum } from '@/types/marketDatum'
import { cammedFormat, formatDecimalToPercentage } from './cammedFormat'

const formatter = new Intl.NumberFormat('ja-JP')

export const formatColumn = (marketDatum: MarketDatum, column: string): string => {
  const value = marketDatum?.[column]

  if (
    ['rent', 'rent_maintenance_fee', 'rent_maintenance_fee_unit_price_tsubo'].includes(column) &&
    (value || value === 0)
  ) {
    return `${formatter.format(value)}円`
  } else if (column === 'rent_maintenance_fee') {
    return `${formatter.format(value)}円`
  } else if (['prospective_yield', 'sale_prospective_yield'].includes(column) && value) {
    return `${formatDecimalToPercentage(value).toFixed(2)}%`
  } else if (column === 'build_age') {
    if (!value) return ''
    if (value === 0 || value === '新築') return '新築'

    const match = value.match(/(\d+)年(?:(\d+)ヶ月)?/)
    if (match) {
      const years = parseInt(match[1])
      const months = match[2] ? parseInt(match[2]) : 0

      if (months === 0) {
        return `築${years}年`
      } else {
        return `築${years}年${months}ヶ月`
      }
    }
    return `築${value}`
  } else if (column === 'occupied_area' && value) {
    return `${cammedFormat(value, 2)}㎡`
  } else {
    return value || 'ー'
  }
}
