import * as React from 'react'
import { formatLatLngs } from './formatLatLngs'
import { Row, Th, Td } from '@/components/Table'
import { Input } from '@/components/Input'
import { Select } from '@/components/Select'
import type { User } from '@/types/user'
import '@/components/GoogleMapVolume/style.css'
import {dataPrecisionSetup} from "@/utils/cammedFormat";

type Props = {
  property: any
  propertyShape: any
  onChangeParameters?: () => void
  ref_volume_check_parameters?: any
  checkboxValues: any
  onCheckboxChange?: (newValue: any) => void
  inverseShadow: string
  onInverseShadowChange: (newValue: string) => void
  assumedVolumeDigestionFloor: string
  onAssumedVolumeDigestionFloorChange: (newValue: string) => void
  calculationOrder: string
  onCalculationOrderChange: (newValue: string) => void
  current_user: User
  env_name: string
}

type LatLng = number[]

type DefaultSettings = {
  floor_height: string
  floors_above_ground: string
  floors_under_ground: string
  max_height: string
  gf_slab_level: string
  design_ground_level: string
  balcony_width: string
}
const setOptions = [
  {
    value: '0',
    text: 'コンパクトレジデンス',
  },
  {
    value: '1',
    text: '日影規制対象外（低層住居専用地域を除く）',
  },
]
const setVerifyOptions = [
  {
    value: '0',
    text: 'EV台数',
  },
  {
    value: '1',
    text: '管理人室設置',
  },
  {
    value: '2',
    text: 'ゴミ置場設置',
  },
  {
    value: '3',
    text: '指導要綱適用',
  },
  {
    value: '4',
    text: '天空率適用',
  },
  {
    value: '5',
    text: '地区計画適用',
  },
  {
    value: '6',
    text: '都市計画道路適用',
  },
]
const ExtendsForm3: React.FC<Props> = ({
  property,
  propertyShape,
  onChangeParameters,
  ref_volume_check_parameters,
  checkboxValues,
  onCheckboxChange,
  inverseShadow,
  onInverseShadowChange,
  assumedVolumeDigestionFloor,
  onAssumedVolumeDigestionFloorChange,
  calculationOrder,
  onCalculationOrderChange,
  current_user,
  env_name,
}) => {
  let latlngs: LatLng[]

  const [useRefVolumeParams, setUseRefVolumeParams] = React.useState(true)

  const refBuildingSetting = ref_volume_check_parameters
    ? JSON.parse(ref_volume_check_parameters).building_setting
    : null

  const refCityPlanningSetting = ref_volume_check_parameters
    ? JSON.parse(ref_volume_check_parameters).city_planning_setting
    : null

  const refUnitSetting = ref_volume_check_parameters
    ? JSON.parse(ref_volume_check_parameters).unit_setting
    : null

  const ref_floor_height = refBuildingSetting?.floor_height || null
  const ref_floors_above_ground = refBuildingSetting?.floors_above_ground || null
  const ref_floors_under_ground = refBuildingSetting?.floors_under_ground || null
  const ref_max_height = refBuildingSetting?.max_height || null
  const ref_gf_slab_level = refBuildingSetting?.gf_slab_level || null
  const ref_design_ground_level = refBuildingSetting?.design_ground_level || null
  const ref_balcony_width = refUnitSetting?.balcony_width || null
  const ref_dwelling_unit_frontage = refUnitSetting?.dwelling_unit_frontage || null
  const ref_hallway_width = refUnitSetting?.hallway_width || null
  const ref_entrance_area = refUnitSetting?.entrance_area || null
  const ref_min_offset_neighbor = refBuildingSetting?.min_offset_neighbor || null
  const ref_min_offset_road = refBuildingSetting?.min_offset_road || null
  const ref_number_of_units = refBuildingSetting?.number_of_units || null
  const ref_unit_area = refUnitSetting?.unit_area || '25'
  const ref_elevator_number = refUnitSetting?.elevator_number ?? null
  const ref_admin_room = refUnitSetting?.admin_room ?? null
  const ref_waste_storage = refUnitSetting?.waste_storage ?? null
  const ref_guideline = refCityPlanningSetting?.guideline ?? null
  const ref_sky_factor = refBuildingSetting?.sky_factor ?? null
  const ref_district_planning = refCityPlanningSetting?.district_planning ?? null
  const ref_city_planning_road = refCityPlanningSetting?.city_planning_road ?? null
  let tmp = []
  let ref_verify: string[]
  if (
    ref_elevator_number === null &&
    ref_admin_room === null &&
    ref_waste_storage === null &&
    ref_guideline === null &&
    ref_sky_factor === null &&
    ref_district_planning === null &&
    ref_city_planning_road === null
  ) {
    ref_verify = ['0', '1', '2', '3', '4', '5', '6']
  } else {
    if (ref_elevator_number === 1) {
      tmp.push('0')
    }
    if (ref_admin_room === 1) {
      tmp.push('1')
    }
    if (ref_waste_storage === 1) {
      tmp.push('2')
    }
    if (ref_guideline === 1) {
      tmp.push('3')
    }
    if (ref_sky_factor === 1) {
      tmp.push('4')
    }
    if (ref_district_planning === 1) {
      tmp.push('5')
    }
    if (ref_city_planning_road === 1) {
      tmp.push('6')
    }
    ref_verify = tmp
  }

  if (propertyShape) {
    const latlngsFromPropertyShape = JSON.parse(propertyShape)
    latlngs = formatLatLngs(latlngsFromPropertyShape, 'ExtendsForm')
  } else {
    const parameters = ref_volume_check_parameters ? JSON.parse(ref_volume_check_parameters) : null

    if (parameters && parameters.borders) {
      latlngs = parameters.borders
        .sort((a, b) => a.id - b.id)
        .map((border) => [border.to.lat, border.to.lng])
    } else {
      latlngs = (property?.shape?.latlngs ?? [])
        .sort((a, b) => a.no - b.no)
        .map((latlng) => [latlng.lat, latlng.lng])
    }
  }

  const [buildingSettings, setBuildingSettings] = React.useState({
    floor_height: '',
    floors_above_ground: '',
    floors_under_ground: '',
    max_height: '',
    gf_slab_level: '',
    design_ground_level: '',
    balcony_width: '',
    setting_building_area_ratio: '0',
    setting_building_area_ratio_high: '0',
    setting_building_area_ratio_low: '0',
  })

  const [extendsSettings, setExtendsSettings] = React.useState({
    dwelling_unit_frontage: ref_dwelling_unit_frontage
      ? ref_dwelling_unit_frontage
      : ref_unit_area
      ? `${(3 / 25) * ref_unit_area}`
      : '3',
    hallway_width: ref_hallway_width ? ref_hallway_width : '1.2',
    entrance_area: ref_entrance_area ? ref_entrance_area : '',
    min_offset_neighbor: ref_min_offset_neighbor ? ref_min_offset_neighbor : '0.6',
    min_offset_road: ref_min_offset_road ? ref_min_offset_road : '0.6',
    number_of_units: ref_number_of_units ? ref_number_of_units : '20',
    verify: ref_verify,
  })

  let defaultSettings: DefaultSettings
  React.useEffect(() => {
    if (checkboxValues.find((f) => f === '0') && checkboxValues.find((f) => f === '1')) {
      defaultSettings = {
        floor_height: '2.62',
        floors_above_ground: '4',
        floors_under_ground: '0',
        max_height: '9.98',
        gf_slab_level: '-0.5',
        design_ground_level: '0',
        balcony_width: '0',
      }
    } else if (checkboxValues.find((f) => f === '0')) {
      defaultSettings = {
        floor_height: '2.58',
        floors_above_ground: '5',
        floors_under_ground: '0',
        max_height: '13.00',
        gf_slab_level: '0.1',
        design_ground_level: '0',
        balcony_width: '0',
      }
    } else if (checkboxValues.find((f) => f === '1')) {
      defaultSettings = {
        floor_height: '2.62',
        floors_above_ground: '4',
        floors_under_ground: '0',
        max_height: '9.98',
        gf_slab_level: '-0.5',
        design_ground_level: '0',
        balcony_width: '1.1',
      }
    } else {
      defaultSettings = {
        floor_height: '3',
        floors_above_ground: '15',
        floors_under_ground: '0',
        max_height: '50',
        gf_slab_level: '0',
        design_ground_level: '0',
        balcony_width: '1.1',
      }
    }

    setBuildingSettings({
      floor_height:
        useRefVolumeParams && ref_floor_height ? ref_floor_height : defaultSettings.floor_height,
      floors_above_ground:
        useRefVolumeParams && ref_floors_above_ground
          ? ref_floors_above_ground
          : defaultSettings.floors_above_ground,
      floors_under_ground:
        useRefVolumeParams && ref_floors_under_ground
          ? ref_floors_under_ground
          : defaultSettings.floors_under_ground,
      max_height:
        useRefVolumeParams && ref_max_height ? ref_max_height : defaultSettings.max_height,
      gf_slab_level:
        useRefVolumeParams && ref_gf_slab_level ? ref_gf_slab_level : defaultSettings.gf_slab_level,
      design_ground_level:
        useRefVolumeParams && ref_design_ground_level
          ? ref_design_ground_level
          : defaultSettings.design_ground_level,
      balcony_width:
        useRefVolumeParams && ref_balcony_width ? ref_balcony_width : defaultSettings.balcony_width,
      setting_building_area_ratio: '0,-10,-20,-30',
      setting_building_area_ratio_high: '-30,-40,-45,-49',
      setting_building_area_ratio_low: '0,-10,-20,-30',
    })
  }, [checkboxValues, useRefVolumeParams, ref_volume_check_parameters])

  const editableFields = {
    compactResidence: ['max_height'],
    shadeRegulation: ['balcony_width'],
    common: ['floor_height', 'gf_slab_level'],
  }

  const isEditable = (field) => {
    if (!checkboxValues.find((f) => f === '0') && !checkboxValues.find((f) => f === '1'))
      return true

    if (checkboxValues.find((f) => f === '0') && checkboxValues.find((f) => f === '1')) {
      return editableFields.common.includes(field)
    }

    if (checkboxValues.find((f) => f === '1') && !checkboxValues.find((f) => f === '0')) {
      return editableFields.shadeRegulation.includes(field) || editableFields.common.includes(field)
    }

    if (checkboxValues.find((f) => f === '0') && !checkboxValues.find((f) => f === '1')) {
      return (
        editableFields.compactResidence.includes(field) || editableFields.common.includes(field)
      )
    }

    return false
  }

  const handleFloorsAboveGround = (num: string) => {
    if (Number(num) > 15) {
      num = "15"
    }
    return num
  }

  return (
    <>
      <tr className="bg-[#F1F3F4] [&:nth-child(even)]:bg-white">
        <td colSpan={2}>
          <table className="w-full text-sm text-left text-black-base">
            <tbody>
              <tr>
                <th
                  colSpan={2}
                  className="md:table-cell md:text-xs px-4 pt-1 pb-2 whitespace-normal text-primary font-small"
                >
                  <div>※Ctrlキーを押しながらクリックすることで、選択解除や複数選択が可能です。</div>
                </th>
              </tr>
              <tr>
                <Th>オプション</Th>
                <Td>
                  <div className="flex gap-4 flex-col md:flex-row">
                    <Select
                      multiple
                      className="text-sm bg-none md:w-[310px]"
                      name="regulation"
                      id="regulation"
                      options={setOptions}
                      value={checkboxValues}
                      onChange={(e) => {
                        const values = Array.from(
                          e.target.selectedOptions,
                          (option) => option.value
                        )
                        onCheckboxChange(values)
                        setUseRefVolumeParams(false)
                        onChangeParameters()
                      }}
                    />
                    <div className="md:w-[290px]"></div>
                  </div>
                </Td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <Row label={<Th>inverseShadow</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <input
              type="radio"
              id="inverse_shadow_true"
              name="inverse_shadow"
              value="0"
              checked={inverseShadow === '0'}
              onChange={(e) => {
                onInverseShadowChange(e.target.value)
                onChangeParameters()
              }}
            />
            <label htmlFor="inverse_shadow_true"> 高層</label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <input
              type="radio"
              id="inverse_shadow_false"
              name="inverse_shadow"
              value="1"
              checked={inverseShadow === '1'}
              onChange={(e) => {
                onInverseShadowChange(e.target.value)
                onChangeParameters()
              }}
            />
            <label htmlFor="inverse_shadow_false"> 低層</label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <input
              type="radio"
              id="inverse_shadow_both"
              name="inverse_shadow"
              value="2"
              checked={inverseShadow === '2'}
              onChange={(e) => {
                onInverseShadowChange(e.target.value)
                onChangeParameters()
              }}
            />
            <label htmlFor="inverse_shadow_both"> 両方</label>
          </div>
        </Td>
      </Row>
      <Row label={<Th>設定建ぺい率</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <Input
              className="md:w-[300px]"
              id="setting_building_area_ratio_high"
              name="setting_building_area_ratio_high"
              prefix="高層"
              suffix="%"
              value={buildingSettings.setting_building_area_ratio_high}
              placeholder="例 -10,-20,-40"
              disabled={inverseShadow === '1'}
              onChange={(e) => {
                const value = e.target.value
                const regex = /^[0-9,\-]*$/
                const lastChar = value.charAt(value.length - 1)

                if (regex.test(value)) {
                  setBuildingSettings({
                    ...buildingSettings,
                    setting_building_area_ratio_high: value,
                  })
                  onChangeParameters()
                } else if (!lastChar || !regex.test(lastChar)) {
                  e.target.value = value.slice(0, -1)
                }
              }}
              onBlur={(e) => {
                let value = e.target.value
                value = value.replace(/,+/g, ',').replace(/^,|,$/g, '')
                value = value.replace(/-+/g, '-')
                value = value.replace(/-$/, '')
                value = value.trim()
                setBuildingSettings({
                  ...buildingSettings,
                  setting_building_area_ratio_high: value,
                })
                onChangeParameters()
              }}
            />
            <Input
              className="md:w-[300px]"
              id="setting_building_area_ratio_low"
              name="setting_building_area_ratio_low"
              prefix="低層"
              suffix="%"
              value={buildingSettings.setting_building_area_ratio_low}
              placeholder="例 -10,-20,-40"
              disabled={inverseShadow === '0'}
              onChange={(e) => {
                const value = e.target.value
                const regex = /^[0-9,\-]*$/
                const lastChar = value.charAt(value.length - 1)

                if (regex.test(value)) {
                  setBuildingSettings({
                    ...buildingSettings,
                    setting_building_area_ratio_low: value,
                  })
                  onChangeParameters()
                } else if (!lastChar || !regex.test(lastChar)) {
                  e.target.value = value.slice(0, -1)
                }
              }}
              onBlur={(e) => {
                let value = e.target.value
                value = value.replace(/,+/g, ',').replace(/^,|,$/g, '')
                value = value.replace(/-+/g, '-')
                value = value.replace(/-$/, '')
                value = value.trim()
                setBuildingSettings({
                  ...buildingSettings,
                  setting_building_area_ratio_low: value,
                })
                onChangeParameters()
              }}
            />
          </div>
        </Td>
      </Row>
      <Row label={<Th>容積消化階</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <input
              type="radio"
              id="assumed_volume_digestion_floor_0"
              name="assumed_volume_digestion_floor"
              value="0"
              checked={assumedVolumeDigestionFloor === '0'}
              onChange={(e) => {
                onAssumedVolumeDigestionFloorChange(e.target.value)
                onChangeParameters()
              }}
            />
            <label htmlFor="assumed_volume_digestion_floor_0"> 基準階</label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <input
              type="radio"
              id="assumed_volume_digestion_floor_1"
              name="assumed_volume_digestion_floor"
              value="1"
              checked={assumedVolumeDigestionFloor === '1'}
              onChange={(e) => {
                onAssumedVolumeDigestionFloorChange(e.target.value)
                onChangeParameters()
              }}
            />
            <label htmlFor="assumed_volume_digestion_floor_1"> +1層</label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <input
              type="radio"
              id="assumed_volume_digestion_floor_2"
              name="assumed_volume_digestion_floor"
              value="2"
              checked={assumedVolumeDigestionFloor === '2'}
              onChange={(e) => {
                onAssumedVolumeDigestionFloorChange(e.target.value)
                onChangeParameters()
              }}
            />
            <label htmlFor="assumed_volume_digestion_floor_2"> +2層</label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <input
              type="radio"
              id="assumed_volume_digestion_floor_both"
              name="assumed_volume_digestion_floor"
              value="3"
              checked={assumedVolumeDigestionFloor === '3'}
              onChange={(e) => {
                onAssumedVolumeDigestionFloorChange(e.target.value)
                onChangeParameters()
              }}
            />
            <label htmlFor="assumed_volume_digestion_floor_both"> 全部</label>
          </div>
        </Td>
      </Row>
      <Row label={<Th>計算順番</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <input
              type="radio"
              id="calculation_order_0"
              name="calculation_order"
              value="0"
              checked={calculationOrder === '0'}
              onChange={(e) => {
                onCalculationOrderChange(e.target.value)
                onChangeParameters()
              }}
            />
            <label htmlFor="calculation_order_0"> 天空率 ⇒ 日影・斜線</label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <input
              type="radio"
              id="calculation_order_1"
              name="calculation_order"
              value="1"
              checked={calculationOrder === '1'}
              onChange={(e) => {
                onCalculationOrderChange(e.target.value)
                onChangeParameters()
              }}
            />
            <label htmlFor="calculation_order_1"> 日影・斜線 ⇒ 天空率</label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <input
              type="radio"
              id="calculation_order_both"
              name="calculation_order"
              value="2"
              checked={calculationOrder === '2'}
              onChange={(e) => {
                onCalculationOrderChange(e.target.value)
                onChangeParameters()
              }}
            />
            <label htmlFor="calculation_order_both"> 全部</label>
          </div>
        </Td>
      </Row>

      <Row label={<Th>階数</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <Input
              className="md:w-[300px]"
              id="floors_above_ground"
              name="floors_above_ground"
              prefix="地上"
              suffix="階"
              value={buildingSettings.floors_above_ground}
              readOnly={!isEditable('floors_above_ground')}
              onChange={(e) => {
                const value = e.target.value
                if (/^\d*$/.test(value)) {
                  setBuildingSettings({ ...buildingSettings, floors_above_ground: handleFloorsAboveGround(value) })
                  onChangeParameters()
                }
              }}
            />
            <Input
              className="md:w-[300px]"
              id="floors_under_ground"
              name="floors_under_ground"
              prefix="地下"
              suffix="階"
              value={buildingSettings.floors_under_ground}
              readOnly={!isEditable('floors_under_ground')}
              //課題256暫定対応↓
              disabled={current_user.role !== 'system_admin'}
              //課題256暫定対応↑
              onChange={(e) => {
                const value = e.target.value
                if (/^\d*$/.test(value)) {
                  setBuildingSettings({ ...buildingSettings, floors_under_ground: value })
                  onChangeParameters()
                }
              }}
            />
          </div>
        </Td>
      </Row>
      <Row label={<Th>建物の最大高さ</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <Input
              className="md:w-[300px]"
              id="max_height"
              name="max_height"
              suffix="m"
              value={buildingSettings.max_height}
              readOnly={!isEditable('max_height')}
              onChange={(e) => {
                setBuildingSettings({ ...buildingSettings, max_height: e.target.value })
                onChangeParameters()
              }}
              onBlur={(e) => {
                const value = dataPrecisionSetup(e.target.value, 12, 2)
                setBuildingSettings({ ...buildingSettings, max_height: value.toString() })
                onChangeParameters()
              }}
            />
          </div>
        </Td>
      </Row>
      <Row label={<Th>階高</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <Input
              className="md:w-[300px]"
              id="max_floor_height"
              name="max_floor_height"
              suffix="m"
              value={buildingSettings.floor_height}
              readOnly={!isEditable('floor_height')}
              onChange={(e) => {
                setBuildingSettings({ ...buildingSettings, floor_height: e.target.value })
                onChangeParameters()
              }}
              onBlur={(e) => {
                const value = dataPrecisionSetup(e.target.value, 12, 2)
                setBuildingSettings({ ...buildingSettings, floor_height: value.toString() })
                onChangeParameters()
              }}
            />
          </div>
        </Td>
      </Row>
      <Row label={<Th>1F Slab Level</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <Input
              className="md:w-[300px]"
              id="gf_slab_level"
              name="gf_slab_level"
              suffix="m"
              value={buildingSettings.gf_slab_level}
              readOnly={!isEditable('gf_slab_level')}
              //課題256暫定対応↓
              disabled={current_user.role !== 'system_admin'}
              //課題256暫定対応↑
              onChange={(e) => {
                setBuildingSettings({ ...buildingSettings, gf_slab_level: e.target.value })
                onChangeParameters()
              }}
            />
          </div>
        </Td>
      </Row>
      <Row label={<Th>設計Ground Level</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <Input
              className="md:w-[300px]"
              id="design_ground_level"
              name="design_ground_level"
              suffix="m"
              value={buildingSettings.design_ground_level}
              readOnly={!isEditable('design_ground_level')}
              //課題256暫定対応↓
              disabled={current_user.role !== 'system_admin'}
              //課題256暫定対応↑
              onChange={(e) => {
                setBuildingSettings({ ...buildingSettings, design_ground_level: e.target.value })
                onChangeParameters()
              }}
            />
          </div>
        </Td>
      </Row>
      <Row label={<Th>バルコニー奥行</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <Input
              className="md:w-[300px]"
              id="balcony_width"
              name="balcony_width"
              suffix="m"
              value={buildingSettings.balcony_width}
              readOnly={!isEditable('balcony_width')}
              onChange={(e) => {
                setBuildingSettings({ ...buildingSettings, balcony_width: e.target.value })
                onChangeParameters()
              }}
              onBlur={(e) => {
                const value = dataPrecisionSetup(e.target.value, 12, 2)
                setBuildingSettings({ ...buildingSettings, balcony_width: value.toString() })
                onChangeParameters()
              }}
            />
          </div>
        </Td>
      </Row>
      <Row label={<Th>希望住戸面積間口</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <Input
              className="md:w-[300px]"
              id="dwelling_unit_frontage"
              name="dwelling_unit_frontage"
              suffix="m"
              value={extendsSettings.dwelling_unit_frontage}
              onChange={(e) => {
                setExtendsSettings({ ...extendsSettings, dwelling_unit_frontage: e.target.value })
                onChangeParameters()
              }}
              onBlur={(e) => {
                const value = dataPrecisionSetup(e.target.value, 12, 2)
                setExtendsSettings({ ...extendsSettings, dwelling_unit_frontage: value })
                onChangeParameters()
              }}
            />
          </div>
        </Td>
      </Row>
      <Row label={<Th>廊下幅員</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <Input
              className="md:w-[300px]"
              id="hallway_width"
              name="hallway_width"
              suffix="m"
              value={extendsSettings.hallway_width}
              onChange={(e) => {
                setExtendsSettings({ ...extendsSettings, hallway_width: e.target.value })
                onChangeParameters()
              }}
              onBlur={(e) => {
                const value = dataPrecisionSetup(e.target.value, 12, 2)
                setExtendsSettings({ ...extendsSettings, hallway_width: value })
                onChangeParameters()
              }}
            />
          </div>
        </Td>
      </Row>
      <Row className={current_user.role === "system_admin" ? '':'row_hidden'} label={<Th>エントランス</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <Input
                className="md:w-[300px]"
                id="entrance_area"
                name="entrance_area"
                suffix="戸"
                value={extendsSettings.entrance_area}
                onChange={(e) => {
                  const value = e.target.value
                  if (/^\d*$/.test(value)) {
                    setExtendsSettings({ ...extendsSettings, entrance_area: value })
                    onChangeParameters()
                  }
                }}
            />
          </div>
        </Td>
      </Row>
      {current_user.role === "system_admin" ? (<></>): (<Row className='row_hidden'></Row>)}
      <Row label={<Th>隣地境界線からの最小離隔距離</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <Input
              className="md:w-[300px]"
              id="min_offset_neighbor"
              name="min_offset_neighbor"
              suffix="m"
              value={extendsSettings.min_offset_neighbor}
              onChange={(e) => {
                setExtendsSettings({ ...extendsSettings, min_offset_neighbor: e.target.value })
                onChangeParameters()
              }}
              onBlur={(e) => {
                const value = dataPrecisionSetup(e.target.value, 12, 2)
                setExtendsSettings({ ...extendsSettings, min_offset_neighbor: value })
                onChangeParameters()
              }}
            />
          </div>
        </Td>
      </Row>
      <Row label={<Th>道路境界線からの最小離隔距離</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <Input
              className="md:w-[300px]"
              id="min_offset_road"
              name="min_offset_road"
              suffix="m"
              value={extendsSettings.min_offset_road}
              onChange={(e) => {
                setExtendsSettings({ ...extendsSettings, min_offset_road: e.target.value })
                onChangeParameters()
              }}
              onBlur={(e) => {
                const value = dataPrecisionSetup(e.target.value, 12, 2)
                setExtendsSettings({ ...extendsSettings, min_offset_road: value })
                onChangeParameters()
              }}
            />
          </div>
        </Td>
      </Row>
      <Row className={current_user.role === "system_admin" ? '':'row_hidden'} label={<Th>住戸／階</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <Input
              className="md:w-[300px]"
              id="number_of_units"
              name="number_of_units"
              suffix="戸"
              value={extendsSettings.number_of_units}
              onChange={(e) => {
                const value = e.target.value
                if (/^\d*$/.test(value)) {
                  setExtendsSettings({ ...extendsSettings, number_of_units: value })
                  onChangeParameters()
                }
              }}
            />
          </div>
        </Td>
      </Row>
      <Row className={current_user.role === "system_admin" ? '':'row_hidden'} label={<Th>検証オプション</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <Select
                multiple
                className="text-sm bg-none md:w-[300px]"
                name="verify"
                id="verify"
                options={setVerifyOptions}
                value={extendsSettings.verify}
                onChange={(e) => {
                  const values = Array.from(e.target.selectedOptions, (option) => option.value)
                  setExtendsSettings({ ...extendsSettings, verify: values })
                  onChangeParameters()
                }}
            />
          </div>
        </Td>
      </Row>
      <input type="hidden" id="count_borders" name="count_borders" value={latlngs.length} />
    </>
  )
}

export default ExtendsForm3
